import { ActionReducerMap } from '@ngrx/store';
import { CatalogueSaleList, NotificationObject } from './contratos/models/contrato.model';
import { detailContratoReducer, showroomReducer } from './contratos/contratos-v2/state/contratos.reducers';
import { ItemCarantyHostCRM, ListEstado, ListShowroom, ShowRoomStateAll } from '@core/models/responsehttp.model';
import { AsociadoObject, ContratoDetail, SellerObject } from './core/models/contrato-mc.model';

import { stateGlobalReducer } from '@core/stateGlobal/stateGlobal.reducers';
import { UserSession } from '@core/models/user-roles.model';
import { ProfileType } from '@core/models/utils.model';
import { CarFirebaseObject } from '@core/models/car-firebase.model';
import { stateExpedienteReducer } from './expediente/state';
import { FilterDocumentCatalogue, ItemCatalogueExpedienteCar } from '@core/models/expediente-car.model';
import { TallerInspeccionObject } from './pagos-inspeccion/models/pagos-inspeccion.model';
import { stateUserReducer } from './usuario/state';

export interface SaleState {
  loading: boolean;
  catalogueSaleList: CatalogueSaleList;
}

export interface ShowroomState {
  loading: boolean;
  loadingInit: boolean;
  showrooms: ListShowroom[];
}

export interface DetailContratoState {
  loading: boolean;
  contrato: ContratoDetail;
  savedCar: boolean;
  savedBuyer: boolean;
  savedSeller: boolean;
  savedSale: boolean;
  updateSale: boolean;
  generatePDF: boolean;
  asociate: AsociadoObject[];
}

export interface GlobalState {
  state?: ListEstado[];
  showroomsAll?: ShowRoomStateAll[];
  loadedState: boolean;
  carantyhostCRM?: ItemCarantyHostCRM[];
  listAllCHostCompany?: ProfileType[];
  listCHostAquienPuedeVer?: ProfileType[];
  loadedCarantyhostCRM: boolean;
  banks?: string[];
  loadedBanks: boolean;
  userSession: UserSession;
  loadingGraph?: boolean;
  resourcesCRM?: any;
  carsPublish?: CarFirebaseObject[];
  usersNotificaMC: string[];
  usersAutorizaComisionContrato: string[];
  usersNotificaMCSolicitudContrato: NotificationObject;
  usersNotificaSolicitudPago: NotificationObject;
  talleresInspeccion?: TallerInspeccionObject[];
}

export interface ExpedienteState {
  catalogueDocumentsExpendediente?: ItemCatalogueExpedienteCar[];
  filterDocuments?: FilterDocumentCatalogue;
}

export interface UserState {}

export interface AppState {
  showroomState: ShowroomState;
  contratoState: DetailContratoState;
  globalState: GlobalState;
  expedienteState: ExpedienteState;
  userState: UserState;
}

export const ROOT_REDUCERS: ActionReducerMap<AppState> = {
  showroomState: showroomReducer,
  contratoState: detailContratoReducer,
  globalState: stateGlobalReducer,
  expedienteState: stateExpedienteReducer,
  userState: stateUserReducer,
};
